import React, { useCallback, useState } from 'react';
import PT from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

import EditIcon from '@mui/icons-material/Edit';

import FormulationDialog from './FormulationDialog';

const FormulationSelect = ({ parameterId, columns, value, onChange }) => {

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback((value) => {
    onChange(value);
    setOpen(false);
  }, [onChange]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        {value?.description || value?.unit?.id ?
          <>
            <Tooltip
              title={`${value?.description || ''} ${value?.unit?.id ? `(${value?.unit?.name})` : ''}`}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '4px',
                  maxWidth: '100%',
                  minWidth: '0',
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    fontSize: '12px',
                    flexShrink: '1',
                  }}
                >
                  {value?.description}
                </Typography>

                {value?.unit?.id ?
                  <Typography
                    noWrap
                    sx={{
                      color: '#666',
                      fontSize: '12px',
                      flexShrink: '0',
                    }}
                  >
                    ({value?.unit?.name})
                  </Typography> :
                  null
                }
              </Box>
            </Tooltip>

            <Divider
              orientation="vertical"
              sx={{ height: '18px' }}
            />
          </>
          : null
        }

        <IconButton
          size="small"
          onClick={handleOpen}
        >
          <EditIcon
            fontSize="12px"
          />
        </IconButton>
      </Box>

      {open ?
        <FormulationDialog
          parameterId={parameterId}
          columns={columns}
          value={value}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
        : null
      }
    </>
  );
};

FormulationSelect.propTypes = {
  parameterId: PT.string,
  columns: PT.array.isRequired,
  value: PT.string,
  onChange: PT.func,
};

export default FormulationSelect;
