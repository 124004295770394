import React, { useMemo, useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ContentItem from './ContentItem';

const Content = ({
  protocolId,
  columns,
  data,
  readOnly,
  onAddParameter,
  onRemoveParameter,
  onParameterTitleTypeChange,
  onParameterTitleChange,
  onParameterValueTypeChange,
  onParameterValueChange,
  onSetParameterOrder
}) => {
  const droppableId = useMemo(() => {
    return protocolId ?? window.crypto.randomUUID();
  }, [protocolId]);

  const handleDragEnd = useCallback((ev) => {
    if(!ev.destination) return;

    onSetParameterOrder(ev.draggableId, ev.destination.index);
  }, [onSetParameterOrder]);

  return (
    <Box>
      <Typography
        variant="body2"
        fontWeight="500"
      >
        Content
      </Typography>

      <Box
        display="flex"
        gap="8px"
        mt="16px"
      >
        <Box flexBasis="50%">
          <Box
            borderRadius="2px"
            sx={{
              background: '#eaeaea'
            }}
          >
            <Typography
              variant="caption"
              lineHeight="24px"
              px="9px"
            >
              Title
            </Typography>
          </Box>
        </Box>

        <Box flexBasis="50%">
          <Box
            borderRadius="2px"
            sx={{
              background: '#eaeaea'
            }}
          >
            <Typography
              variant="caption"
              lineHeight="24px"
              px="9px"
            >
              Value
            </Typography>
          </Box>
        </Box>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={droppableId}>
          {provided => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {data.ids.map((id, i) => (
                <ContentItem
                  key={id}
                  id={id}
                  index={i}
                  protocolId={protocolId}
                  columns={columns}
                  data={data.byId[id]}
                  readOnly={readOnly}
                  error={data.errorById[id]}
                  onRemove={onRemoveParameter}
                  onTitleTypeChange={onParameterTitleTypeChange}
                  onTitleChange={onParameterTitleChange}
                  onValueTypeChange={onParameterValueTypeChange}
                  onValueChange={onParameterValueChange}
                />
              ))}

              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      {readOnly ?
        null :
        <Button
          sx={{
            marginTop: '8px'
          }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={onAddParameter}
        >
          Add
        </Button>
      }
    </Box>
  );
};

Content.propTypes = {
  protocolId: PT.string,
  columns: PT.array.isRequired,
  data: PT.object,
  readOnly: PT.bool,
  onAddParameter: PT.func,
  onRemoveParameter: PT.func,
  onParameterTitleTypeChange: PT.func,
  onParameterTitleChange: PT.func,
  onParameterValueTypeChange: PT.func,
  onParameterValueChange: PT.func,
  onSetParameterOrder: PT.func
};

export default Content;
