import React, { useCallback, useState } from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

import SuggestionsDialog from './SuggestionsDialog';
import AIFeatureNormal from '../../../../components/Icons/AIFeature/AIFeatureNormal';

const AiSuggestionsButton = ({ tableId, disabled }) => {

  const [open, setOpen] = useState(false);

  const handleToggleOpen = useCallback(() => setOpen(state => !state), []);

  return (
    <>
      <ButtonBase
        sx={{
          color: 'rgba(0, 0, 0, 0.60)'
        }}
        onClick={handleToggleOpen}
        disabled={disabled}
      >
        <AIFeatureNormal
          sx={{ fontSize: '14px', mr: '6px', color: disabled && 'rgba(0, 0, 0, 0.26)' }}
        />

        <Typography
          sx={{
            fontSize: '12px',
            minWidth: 'max-content',
            color: disabled && 'rgba(0, 0, 0, 0.26)'
          }}
        >
          Setup AI Suggestions
        </Typography>

        <Box
          sx={{
            color: 'white',
            background: disabled ? 'rgba(0, 0, 0, 0.26)' : 'linear-gradient(221deg, #54B9D3 28.6%, #3263F6 84.74%)',
            borderRadius: '2px',
            ml: '4px',
            p: '0 2px',
            width: '30px',
            height: '16px',
            fontSize: '10px',
            fontWeight: '700',
            lineHeight: '16px',
          }}
        >
          New!
        </Box>
      </ButtonBase>

      <SuggestionsDialog
        open={open}
        onClose={handleToggleOpen}
        tableId={tableId}
      />
    </>
  );
};

AiSuggestionsButton.propTypes = {
  tableId: PT.string,
  disabled: PT.bool.isRequired,
};

export default AiSuggestionsButton;
