import React, { forwardRef } from 'react';
import PT from 'prop-types';

import AIFeatureNormal from './AIFeatureNormal';
import AIFeatureActive from './AIFeatureActive';

const AIFeature = forwardRef(({ type, ...props }, ref) => {
  switch(type) {
    case 'active':
      return (
        <AIFeatureActive {...props} ref={ref} />
      );

    case 'normal':
    default:
      return (
        <AIFeatureNormal {...props} ref={ref} />
      );
  }
});

AIFeature.propTypes = {
  type: PT.oneOf(['active', 'normal'])
};

export default AIFeature;
